<template>
  <div
    class="print-area"
    style="margin: 0 auto; max-width: 182mm;"
  >
    <div
      class="invoice my-4 d-flex flex-column justify-content-center"
      style="background-color: white; width: 100%; aspect-ratio: 0.7"
    >
      <div class="body my-4">
        <div class="top mx-4">
          <div class="row">
            <div class="col bold text-title">{{ invoice.businessName }}</div>

            <div class="col text-right bold text-title green">Invoice</div>
          </div>
          <div class="row mt-4">
            <div class="col-2 bold">Bill to:</div>
            <div class="col-4">{{ invoice.customerName }}</div>
            <div class="col-2 text-right bold">Invoice No:</div>
            <div class="col-4 text-right">Invoice {{ invoice.name }}</div>
          </div>
          <div class="row">
            <div class="col-2 bold"></div>
            <div class="col-4">{{ invoice.customerPhoneNumber }}</div>
            <div class="col-2 text-right bold">Date:</div>
            <div class="col-4 text-right">
              {{
                invoice.invoiceDate && invoice.invoiceDate._seconds
                  ? format(
                      new Date(invoice.invoiceDate._seconds * 1000),
                      "MM/dd/yyyy"
                    )
                  : ""
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 bold"></div>
            <div class="col-4">{{ invoice.customerEmail }}</div>
            <div class="col-2 text-right bold">Terms:</div>
            <div class="col-4 text-right">Net 7</div>
          </div>
          <div class="row">
            <div class="col-2 bold"></div>
            <div class="col-4"></div>
            <div class="col-2 text-right bold">Due Date:</div>
            <div class="col-4 text-right">
              {{
                invoice.dueDate && invoice.dueDate._seconds
                  ? format(
                      new Date(invoice.dueDate._seconds * 1000),
                      "MM/dd/yyyy"
                    )
                  : ""
              }}
            </div>
          </div>
        </div>
        <div class="bg-green w-100 mt-4" style="height: 3px"></div>
        <div class="px-4 py-2 bold bg-green-op-2">
          <div class="row">
            <div class="col-6">Description</div>
            <div class="col-2 text-right">Quantity</div>
            <div class="col-2 text-right">Rate</div>
            <div class="col-2 text-right">Amount</div>
          </div>
        </div>
        <div
          v-for="(item, index) in invoice.items"
          :key="index"
          class="px-4 py-1"
        >
          <div class="row">
            <div class="col-6">{{ item.name }}</div>
            <div class="col-2 text-right">
              {{ item.qty }} {{ round(item.qtyUnit) }}
            </div>
            <div class="col-2 text-right">
              {{ currency }} {{ round(item.price) }}
            </div>
            <div class="col-2 text-right">
              {{ currency }} {{ round(item.total) }}
            </div>
          </div>
          <div>{{ item.description }}</div>
        </div>
        <div class="px-4 py-1">
          <div class="row py-3">
            <div class="col d-flex flex-column justify-content-center">
              <div class="bold">Payment instructions</div>
              <div>{{ invoice.paymentInstructions }}</div>
            </div>
            <div class="col text-right">
              <div class="row">
                <div class="col">SubTotal</div>
                <div class="col">
                  {{ currency }} {{ round(invoice.subTotal) }}
                </div>
              </div>
              <div class="row">
                <div class="col">Discount</div>
                <div class="col">
                  {{ currency }} {{ round(invoice.discountAmount) }}
                </div>
              </div>
              <div class="row">
                <div class="col">Total</div>
                <div class="col">{{ currency }} {{ round(invoice.total) }}</div>
              </div>
              <div class="row mt-2">
                <div class="col">Paid</div>
                <div class="col">
                  {{ currency }} {{ round(invoice.paidAmount) }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex justify-content-end align-items-center">
              <div
                v-if="invoice.isPaid"
                class="px-2 py-1 bold"
                style="border: 2px solid red; border-radius: 5px; color: red"
              >
                Paid
              </div>
            </div>
            <div class="col">
              <div class="bg-green" style="height: 3px"></div>
              <div class="row py-1 text-right">
                <div class="col">Balance Due</div>
                <div class="col">
                  {{ currency }} {{ round(invoice.dueAmount) }}
                </div>
              </div>
              <div class="bg-green" style="height: 3px"></div>
            </div>
          </div>
          <div class="bold mt-3">Comments</div>
          <div>{{ invoice.comments }}</div>
          <div class="row mt-4">
            <div
              class="col"
              v-if="invoice.signBusiness && invoice.signBusiness.storeURL"
            >
              <img
                width="100"
                height="100"
                v-if="invoice.signBusiness"
                :src="invoice.signBusiness.storeURL"
              />
              <hr />
              <div>
                {{ invoice.signBusiness ? invoice.signBusiness.printName : "" }}
              </div>
              <div>
                {{
                  invoice.signBusiness && invoice.signBusiness.signDate
                    ? format(
                        new Date(invoice.signBusiness.signDate._seconds),
                        "MM/dd/yyyy"
                      )
                    : ""
                }}
              </div>
            </div>
            <div
              class="col"
              v-if="invoice.signCustomer && invoice.signCustomer.storeURL"
            >
              <img
                width="100"
                height="100"
                v-if="invoice.signCustomer"
                :src="invoice.signCustomer.storeURL"
              />
              <hr />
              <div>
                {{ invoice.signCustomer ? invoice.signCustomer.printName : "" }}
              </div>
              <div>
                {{
                  invoice.signCustomer && invoice.signCustomer.signDate
                    ? format(
                        new Date(invoice.signCustomer.signDate._seconds),
                        "MM/dd/yyyy"
                      )
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(photo, index) in invoice.photos"
      :key="index"
      class="photo d-flex my-4 flex-column p-4"
      style="
        background-color: white;
        width: 100%;
        aspect-ratio: 0.7;
        page-break-before: always;
      "
    >
      <div class="text-title bold mt-4">{{ photo.title }}</div>
      <div class="mt-2">{{ photo.description }}</div>
      <div>{{ photo.storeURL }}</div>
      <img
        v-if="photo.storeURL"
        :src="photo.storeURL"
        class="m-auto"
        width="75%"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import round from "../../common/utils";
import { format } from "date-fns";
import { API_URL } from "../../common/constants";
export default {
  name: "Invoices",
  data() {
    this.iid = "";
    return {
      currency: "",
      invoice: {},
    };
  },

  created() {
    this.iid = this.$route.params.iid;
    this.loadInvoiceData();
  },
  methods: {
    round: round,
    format: format,
    loadInvoiceData() {
      this.$store.commit("set", ["loading2", true]);
      axios({
        method: "POST",
        url: `${API_URL}/express/getInvoice`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          iid: this.iid,
        },
      })
        .then((response) => {
          console.log("getCustomerStatus.response", response);
          if (response.data.status) {
            this.currency = response.data.currency;
            this.invoice = response.data.invoice;
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.$store.commit("set", ["loading2", false]);
        });
    },
  },
};
</script>
<style scoped>
.green {
  color: #4daf4e;
}
.bg-green {
  background-color: #4daf4e;
}
.bg-green-op-2 {
  background-color: #4daf4e30;
}

.text-title {
  font-size: 20px;
}

.print-area {
  font-size: 11px;
}

.bold {
  font-weight: bold;
}
@media print {
  body {
    background-color: white;
  }
  body,
  body > div {
    display: none;
  }
  .print-area {
    display: block;
  }
}
</style>
